<template>
  <div>
    <div class="user-loading" v-if="loading">
      <div class="icon-bhp">
        <p>
          {{'以下服务的是由' | langTranslation}}<br/>
          {{'BHP基金会提供。' | langTranslation}}
        </p>
      </div>
    </div>
    <v-common-top :showUserInfo="true"></v-common-top>

    <div class="limit-box">

        <div class="user-income-box" v-if="currency!='FIL'" :class="{'user-income-box-min':noBTC}">
            <div class="uib-detail">
                <div class="title">{{'出租收益'|langTranslation}}</div>
                <strong v-if="!noBTC" class="income"><span>{{user.btc | toFixed(8)}}</span> <em>BTC</em></strong>
                <div v-if="!noBTC" style="margin: 0 0 20px" class="title">≒
                    {{user.btc | btcToMoney(realTimeBTCPriceJP)}} 円
                </div>
                <strong v-if="noBTC" class="income"><span>{{user.money | moneyToShow(2)}}</span> <em>円</em></strong>
                <div v-if="!noBTC" class="title">{{'出租时长' | langTranslation}}
                    <el-tooltip class="item" effect="light" placement="bottom-start">
                        <div slot="content">
                            <h3>{{'组龄是指' | langTranslation}}</h3>
                            <div style="max-width: 260px">
                                <div style="font-size: 14px">
                                    <p>
                                        {{'租龄总计（rentaltime）是把您购买的矿机租赁给我公司时，矿机台数X租赁天数的总数，当该租龄满足1000以上，则享有可购买BTC和BHP双挖矿机的特权。' | langTranslation}}</p>
                                    <p>
                                        {{'然而，关于矿机在租赁时间结束之时，如果取消租赁参加回购活动或者要求退还矿机之时，相对应的租龄总计也同时会被扣除。' | langTranslation}}</p>
                                </div>
                                <div style="margin-top:5px;">
                                    <em>※{{'仅限于在购买设备后出租给我们的情况下，租金收益还会奖励BHP币' | langTranslation}}</em>
                                </div>
                            </div>
                        </div>
                        <i class="icon-font-question-mark"></i>
                    </el-tooltip>
                </div>
                <strong v-if="!noBTC"><span>{{user.calc_age | toFixed(0)}}</span></strong>
            </div>
            <div v-if="site_mark!='pro2'" class="uib-nav" @click="$router.push({  path:noBTC?'/withdraw/':'/withdraw/btc'})">
                <i class="icon-font-right-arrow"></i>
            </div>
        </div>
        <div v-else class="user-income-box user-income-box-min">
            <div class="uib-detail" style="left: 20px">
                <div class="title">合計採掘量</div>

                <strong>{{user.fil_info.fil|toFixed(8)}} FIL</strong>
                <div style="font-size: 14px; color:#4A4A4A;margin-top: 25px; line-height: 150%">
                    <span style="display: inline-block; " :style="{width:user.has_mine?'33%':'50%'}">
                        凍結中
                        <el-popover
                            placement="bottom"
                            title=""
                            width="240"
                            trigger="click"
                            content=""
                            popper-class="pop-lock-fil"
                        >
                        <span class="el-icon-arrow-down" slot="reference"></span>
                        <div class="pop-text">
                          <div>
                            <h3>リニアリリース待ち</h3>
                            <p>{{user.fil_info.lock_fil|toFixed(8)}} FIL</p>
                          </div>
                          <div style="margin-top: 15px">
                            <h3>90日凍結分</h3>
                            <p>{{user.lock_fil|toFixed(8)}} FIL</p>
                          </div>
                        </div>

                    </el-popover>
                        <br/>
                        {{allLockFil|toFixed(8)}} FIL
                    </span>

                    <span v-if="user.has_mine" @click="$router.push({  path:'/mine'})" style="display: inline-block;width:33%">
                        担保中 
                        <span class="el-icon-arrow-down" slot="reference"></span><br/>
                        {{allMineFil|toFixed(8)}} FIL
                    </span>
                    <span style="display: inline-block">
                        送付可能<br/>
                        {{user.fil|toFixed(8)}} FIL
                    </span>
                </div>
            </div>
            <div class="uib-nav" @click="$router.push({  path:'/withdraw/fil'})">
                <i class="icon-font-right-arrow"></i>
            </div>
        </div>

  <div class="user-yd-income">
    <strong v-if="!noBTC"><em>{{'昨日の収益' | langTranslation}}</em> {{(yesterdayIncome[0]||yesterdayIncome1[0]) | toFixed(8)}} <em>{{currency}}</em></strong>
    <strong v-if="noBTC"><em>{{'昨日收入' | langTranslation}}</em> {{yesterdayIncome[1] |moneyToShow(2)}} <em>円</em></strong>
    <router-link to="/income">{{'查看详情' | langTranslation}}</router-link>
  </div>
<template v-if="!noBTC && user.level<99 && currency!='FIL'">
    <div class="user-income-box user-income-box-min" >
        <div class="uib-detail">
            <div class="title">{{'提成收益' | langTranslation}}</div>
            <strong  class="income"><span>{{user.money | moneyToShow(2)}}</span> <em>円</em></strong>

        </div>
        <div class="uib-nav" @click="$router.push({  path: '/withdraw/'})">
            <i class="icon-font-right-arrow"></i>
        </div>
    </div>
    <div class="user-yd-income">
        <strong></strong>
        <router-link to="/income/percentage">{{'查看详情' | langTranslation}}</router-link>
    </div>
</template>


  <div class="user-nav">
    <ul>
      <li>
        <router-link to="/machine"><i class="icon-font-machine"></i><span>{{currency=='FIL'?'購入履歴':'已购买的设备' | langTranslation}}</span>
        </router-link>
      </li>
      <li v-if="currency!='FIL'">
        <router-link to="/machine/hosting"><i class="green-icon iconfont iconlease"></i><span>{{'出租中的设备' | langTranslation}}</span>
        </router-link>
      </li>
      <!--<li>
          <router-link to="/wallet"><i class="icon-font-machine"></i><span>残高情報</span></router-link>
      </li>-->
      <li v-if="currency!='FIL'"><a :href="'mailto:'+email"><i class="icon-font-letter"></i><span>{{'联系我们' | langTranslation}}</span></a></li>
      <li v-else><a href="//rrm.jp" target="_blank"><i class="icon-font-letter"></i><span>{{'联系我们' | langTranslation}}</span></a></li>
      <li>
        <router-link to="/user/edit">
          <i class="green-icon iconfont iconaccount-set"></i>
          <span>{{'账号设置' | langTranslation}}</span>
        </router-link>
      </li>
    </ul>
  </div>

  <!--<div class="user-apply-box" v-if="!noBTC">
    <a href="javascript:">
      <i class="icon-font-join"></i>
      <span>{{'我也想成为推广员' | langTranslation}}</span>
    </a>
  </div>-->
  </div>

  <v-copyright></v-copyright>
  </div>

</template>

<script type="text/javascript">
  import Big from 'big.js' ;
  import vCommonTop from './block/common-top';
  import {loadUserInfo, getYesterdayIncome} from '../api';
  import Vuex from 'vuex';

  export default {
    data() {
      return {
        email: window.EMAIL,
        loading: false,
        user: {
          fil_info:{},
            has_mine:false,
        },
        yesterdayIncome: [0,0],
        yesterdayIncome1: [0,0],
      }
    },
    components: {
      vCommonTop
    },
    computed: {
      ...Vuex.mapState(['realTimeBTCPriceJP','noBTC','currency','site_mark','activeUsers']),
        ...Vuex.mapGetters(['isActiveUser']),
      allLockFil(){
        return Big(this.user.lock_fil||0).add(this.user.fil_info.lock_fil||0).toFixed(8);
      },
      allMineFil(){
        return Big(this.user.pledge||0).add(this.user.mine_fil||0).toFixed(8);
      }
    },
    methods: {},
    watch: {},
    created() {

      loadUserInfo(1).then(json => {
        this.user = json.data;

      }, json => {
        this.$message(json.message);
      });
      getYesterdayIncome(this.currency.toLowerCase()).then(json => {
        this.yesterdayIncome = json.data.income || [0,0];
        this.yesterdayIncome1 = json.data.income1 || [0,0];
      });
      /*setTimeout(()=>{
       this.loading=false;
       },1000);*/
    },
    template: '__template__'
  };
</script>
<style lang="scss">
.el-popover.pop-lock-fil{
  background: #313344;
  border-color: #313344;
  .popper__arrow {
    &,&::after{
      border-bottom-color: #313344!important;
    }
  }
  .pop-text{
    padding-top: 0;
    border: none;
    h3{
      font-size: 16px;
      color:#FFFFFF;
      margin: 5px 0;
    }
    p{
      font-size: 18px;
      font-weight: bold;
      color:#FFE5A8;
      margin: 0;
    }
  }

}
</style>
<style type="text/css" scoped>

  .user-income-box-min{
      height: 150px;
      padding: 0;
  }
</style>
