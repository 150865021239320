<template>
    <div class="page-my">
        <v-common-top></v-common-top>
        <div class="images-box" v-if="!imageBox2Show" ref="imageBox">
            <div>
                <img src="@/images/ht/1.jpg">
            </div>
            <div>
                <img src="@/images/ht/2.jpg">
            </div>
            <div>
                <img src="@/images/ht/3.jpg">
            </div>
        </div>
        <div class="images-box" v-if="imageBox2Show" ref="imageBox2">
            <div>
                <img src="@/images/ht2/1.jpg">
            </div>
            <div>
                <img src="@/images/ht2/2.jpg">
            </div>
            <div>
                <img src="@/images/ht2/3.jpg">
            </div>
            <div>
                <img src="@/images/ht2/4.jpg">
            </div>
            <div>
                <img src="@/images/ht2/5.jpg">
            </div>
        </div>
    </div>

</template>

<script type="text/javascript">
    import vCommonTop from './block/common-top';
    import Vuex from 'vuex';
    import {logout} from "@/page/api";
    import Viewer from "viewerjs";


    export default {
        data() {
            return {
                box2_user_id:[11241, 11208, 11305, 11319, 11324, 11378, 11381, 11391, 11396, 11397, 11399, 11429, 11434, 11436, 11448, 11454, 11466, 11474, 11504, 11545, 11546, 11569, 11579, 11592, 11608, 11657, 11663, 11666, 11674, 11667, 11215, 11297, 11320, 11342, 11421, 11428, 11494, 11527, 11553],
                loading:true
            }
        },
        components:{
            vCommonTop
        },
        computed: {
            ...Vuex.mapState(['userInfo','noBTC','currency']),
            imageBox2Show(){
                if(this.userInfo && this.userInfo.id){
                    return this.box2_user_id.indexOf(this.userInfo.id*1)>=0
                }
                return false;
            }
        },
        methods: {

        },
        watch: {

        },
        created() {

        },
        mounted() {
            this.$nextTick(()=>{
                if(this.$refs.imageBox){
                    let viewer1 = new Viewer(this.$refs.imageBox, {
                        inline: false,
                        toolbar: false,
                        title:false,
                        viewed() {
                            viewer1.zoomTo(1);
                        },
                    });
                }
                if(this.$refs.imageBox2){
                    let viewer2 = new Viewer(this.$refs.imageBox2, {
                        inline: false,
                        toolbar: false,
                        title:false,
                        viewed() {
                            viewer2.zoomTo(1);
                        },
                    });
                }

            })

        },
        template: '__template__'
    };
</script>
<style lang="scss">
   .images-box{
       img{
           width: 100%;
       }
   }

</style>
